import { Observable } from "rxjs";
import { publish } from "rxjs/operators";
import auth from "cp-client-auth!sofe";
import { entries } from "lodash";
import { defaultToAPIUrl } from "./url-prefix.helpers.js";
import { setHeaders } from "./fetcher.helpers.js";

export function fetchWithProgress(url, options) {
  const observable = Observable.create((observer) => {
    sendXhr();

    function sendXhr(retrying = false) {
      // fetch doesn't support progress, so we use XHR
      const xhr = new XMLHttpRequest();
      if (typeof url !== "string") {
        throw new Error(
          `fetchWithProgress must be called with a url string as first parameter`
        );
      }
      url = defaultToAPIUrl(url);
      xhr.open(options.method, url);
      xhr.withCredentials = true;
      entries(options.headers).forEach((header) => {
        xhr.setRequestHeader(header.key, header.value);
      });
      setHeaders((key, value) => xhr.setRequestHeader(key, value));

      const progressTracker = { startTime: new Date().getTime() };
      const boundHandleProgress = handleProgress.bind(progressTracker);

      xhr.upload.addEventListener("progress", boundHandleProgress);
      xhr.addEventListener("progress", boundHandleProgress);

      xhr.addEventListener("load", function () {
        if (xhr.status === 401) {
          if (retrying) {
            // We only retry once
            observer.error(this);
            clearInterval(progressTracker.interval);
          } else {
            // Refresh the auth token and then retry
            auth
              .refreshAuthToken({ clientSecret: "TaxUI:f7fsf29adsy9fg" })
              .then(() => {
                setTimeout(() => {
                  // An error thrown by this function should not go into the .catch
                  sendXhr(true);
                });
              })
              .catch((err) => {
                clearInterval(progressTracker.interval);
                observer.error(err);
              });
          }
        } else if (xhr.status === 200) {
          clearInterval(progressTracker.interval);
          try {
            observer.next(JSON.parse(xhr.responseText));
            observer.complete();
          } catch (err) {
            observer.error(
              `Error parsing json response for url '${url}' -- ${err}`
            );
          }
        } else {
          clearInterval(progressTracker.interval);
          observer.error(
            `Request error: ${options.method} ${url} responded with status ${xhr.status} ${xhr.statusText}`
          );
        }
      });
      xhr.addEventListener("error", (evt) => {
        clearInterval(progressTracker.interval);
        observer.error(evt);
      });
      xhr.addEventListener("abort", (evt) => {
        clearInterval(progressTracker.interval);
        // Abort means they navigated away from Canopy? So no need to throw error?
      });

      xhr.send(options.body);

      function handleProgress(evt) {
        if (!retrying) {
          const clientPercent = evt.loaded / evt.total;
          if (evt.loaded === evt.total && !this.interval) {
            this.interval = setInterval(() => {
              const overallPercent = calculateOverallPercent(
                clientPercent,
                this
              );
              observer.next({ ...evt, clientPercent, overallPercent });
            }, 50);
          }
          const overallPercent = calculateOverallPercent(clientPercent, this);
          observer.next({ ...evt, clientPercent, overallPercent });
        }
      }
    }
  });

  /* We want to share the same subscription between all subscribers, so that we don't make duplicate
   * AJAX requests.
   */
  return observable.pipe(publish());

  function calculateOverallPercent(clientPercent, progressTracker) {
    const SERVER_CLIENT_PERFORMANCE_RATIO = 5;
    const baseServerLatency = options.baseServerLatency || 1500;

    if (clientPercent < 1) {
      return clientPercent / SERVER_CLIENT_PERFORMANCE_RATIO;
    } else {
      if (!progressTracker.clientFinish) {
        progressTracker.clientFinish = new Date().getTime();
        progressTracker.estimatedDuration =
          baseServerLatency +
          (progressTracker.clientFinish - progressTracker.startTime) *
            SERVER_CLIENT_PERFORMANCE_RATIO;
      }

      const timeElapsed = new Date().getTime() - progressTracker.startTime;
      const estimatedPercentage = Math.min(
        timeElapsed / progressTracker.estimatedDuration,
        1
      );
      const userPercentage = Math.max(
        clientPercent / SERVER_CLIENT_PERFORMANCE_RATIO,
        estimatedPercentage
      );

      if (userPercentage >= 1) {
        clearInterval(progressTracker.interval);
      }

      return userPercentage;
    }
  }
}
