import { findIndex, filter } from "lodash";

const STORAGE_KEY = "cp-fetcher-overrides";

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const NO_OVERRIDE = "NO_OVERRIDE";

export function getOverrides() {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
  } catch (error) {
    return [];
  }
}

export async function checkOverrides(args) {
  if (localStorage.getItem(STORAGE_KEY)) {
    try {
      let url = args[0] instanceof Request ? args[0].url : args[0];

      const overrides = getOverrides();

      const override = overrides.find((override) => url.includes(override.url));

      if (override) return override;
    } catch {
      console.error(`You have an invalid override for the request: ${args[0]}`);
      return NO_OVERRIDE;
    }
  }
  return NO_OVERRIDE;
}

export function updateOverride(id, override) {
  let overrides = getOverrides();
  const index = findIndex(overrides, { id });
  overrides[index] = override;

  localStorage.setItem(STORAGE_KEY, JSON.stringify(overrides));
}

export function addOverride(options) {
  let { url, status, body, delay = 0, delayOnly } = options;

  let overrides = getOverrides();

  overrides = [
    ...overrides,
    {
      id: uuidv4(),
      url,
      delayOnly,
      delay,
      status,
      body,
    },
  ];

  localStorage.setItem(STORAGE_KEY, JSON.stringify(overrides));
}

export function removeOverride(id) {
  let overrides = getOverrides();

  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify(filter(overrides, (override) => override.id !== id))
  );
}

export function generateMockResponse(override) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        ok: override.status >= 200 && override.status < 300,
        status: override.status,
        text: () => Promise.resolve(JSON.stringify(override.body)),
        json: () => Promise.resolve(override.body),
        statusText: "override",
        headers: [],
      });
    }, override.delay);
  });
}
